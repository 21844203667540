<template>
    <div>
        <div class="default_box">
            <div class="default_header">
                <h1>Восстановление пароля</h1>
            </div>
            <div class="default_box_content bg_white p15" v-if="preloader">
                <div class="success_box" v-if="success" v-html="success"></div>
                <form @submit.prevent="resetPassword" autocomplete="off" v-if="form_vis">
                    <label for="email" class="main_input_label">E-mail</label>
                    <input id="email" type="email" autocomplete="off" class="main_input" v-model="email" minlength="4" required>
                    <button class="button button_default" :disabled="disabled" type="submit">Восстановить пароль</button>
                </form>
            </div>
            <div v-else>
                <PreLoadBox />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "PasswordReset",
    components: {},
    metaInfo() {
        return {
            title: `Восстановление пароля`,
            meta: [
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: `Восстановление пароля`},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preloader: 'finish',
            form_vis: true,
            email: null,
            success: null,
            disabled: false
        }
    },
    methods: {
        api(email) {
            this.preloader = null;
            let url = this.$config.api_url + 'User.passwordReset';
            this.$http.get(url, {
                params: {
                    email
                }
            }).then(() => {
                this.success = 'На ваш почтовый ящик отправлено письмо с ссылкой для восстановления пароля';
                this.form_vis = false;
            }).finally(() => {
                this.preloader = 'finish';
            })
        },
        resetPassword() {
            this.api(this.email);
        },

    }
}
</script>
<style scoped>
.user_reset_password_header {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 15px 0;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.user_reset_password_card {
    width: 100%;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 10px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.user_reset_password_label {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
    font-weight: bold;
}

.success_box {
    background: #388e3c;
    padding: 15px;
    border-radius: 12px;
    color: #fff;
}
</style>